.dateStyle {
    font-size: 20px !important;
}

.interButtons {
    line-height: 300%;
}

.posPadding {
    padding-left: 100px !important;
}