.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 15px;
  }
  
  .container img {
    width: 100%;
    height: auto;
    display: block;
    transition: all 100ms ease-out;
  }
  
  .container img:hover {
    transform: scale(1.04);
  }