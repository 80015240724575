.sound-div {
    width: 50px;
    height: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    transform: perspective(50px) rotateY(-42deg);
    -webkit-transform: perspective(50px) rotateY(-42deg);
    -moz-transform: perspective(50px) rotateY(-42deg);
    -ms-transform: perspective(50px) rotateY(-42deg);
    -o-transform: perspective(50px) rotateY(-42deg);
    animation-name: rotate-windows;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }

  @keyframes rotate-windows {
    0% {
      transform: perspective(50px) rotateY(-42deg);
      -webkit-transform: perspective(50px) rotateY(-42deg);
      -moz-transform: perspective(50px) rotateY(-42deg);
      -ms-transform: perspective(50px) rotateY(-42deg);
      -o-transform: perspective(50px) rotateY(-42deg);
    }
    50% {
      transform: perspective(50px) rotateY(0deg);
      -webkit-transform: perspective(50px) rotateY(0deg);
      -moz-transform: perspective(50px) rotateY(0deg);
      -ms-transform: perspective(50px) rotateY(0deg);
      -o-transform: perspective(50px) rotateY(0deg);
    }
    100% {
      transform: perspective(50px) rotateY(-42deg);
      -webkit-transform: perspective(50px) rotateY(-42deg);
      -moz-transform: perspective(50px) rotateY(-42deg);
      -ms-transform: perspective(50px) rotateY(-42deg);
      -o-transform: perspective(50px) rotateY(-42deg);
    }
  }