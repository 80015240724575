@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}

.navbar h1 {
  color: #f1356d;
}
.navbar .links {
  margin-left: auto;
}

.navbar .links .nav-item a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}

.navbar .links .nav-item a:hover {
  color: #f1356d;
}
.content {
  max-width: 100%;
  margin: 5px auto;
  padding-left: 20px;
  padding-right: 20px;
}

/* blog previews / list */
.blog-preview {
  padding: 10px 16px;
  margin: 20px 0;
  border-bottom: 1px solid black;
}
.blog-preview:hover {
  background-color: lightgrey;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
}
.blog-preview h2 {
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 8px;
}
.blog-preview a {
  text-decoration: none;
}

/* blog details page */
.blog-details h2 {
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 10px;
}
.blog-details div {
  margin: 20px 0;
}
.blog-details button {
  background: #f1356d;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

/* create new blog form */
.create {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.create label {
  text-align: left;
  display: block;
}
.create h2 {
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 30px;
}
.create input,
.create textarea,
.create select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}
.create button {
  background: #f1356d;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}