.Contact {
    text-align: center;
    max-width: 300px;
    margin: 20px auto;
  }
  Form {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 20px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }
