@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  font-family: 'Rubik', sans-serif;
}

$light: #ffffff;
$gray: #e7e9eb;

.sidebar {
  width: fit-content;
  height: calc(100vh - 60px);
  border-right: 1px solid $gray;
  margin-top: 10px;
  ul {
    list-style: none;
    padding: 1rem 2rem;
    li {
      margin-bottom: 1rem;
      a {
        text-decoration: none;
        &.active {
          background-color: $gray;
        }
      }
    }
  }
}


